import { css } from "@emotion/react";
import React from "react";
import PulseLoader from "react-spinners/PulseLoader";

import Layout from "../Layout";
import Takeover from "../Takeover/Takeover";

export default function LoadingScreen({ label }) {
  return (
    <>
      <Layout />
      <div>
        <Takeover>
          <span
            css={css`
              opacity: 0.25;
            `}
          >
            <PulseLoader size={20} />
          </span>
          <span
            css={css`
              opacity: 0.75;
              margin-top: 1em;
            `}
          >
            {label}
          </span>
        </Takeover>
      </div>
    </>
  );
}
